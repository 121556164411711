import axios from 'axios';
import React, { ReactElement, useEffect, useState } from 'react';
import { API_ADDRESS } from 'config/config';
import { useAuth } from 'contexts/AuthContext';
import styled from 'styled-components';
import { formatDateToString } from 'helpers/helper-functions';
import { isImgUrl } from 'utils/IsImageUrl';
import { FileIconUri } from 'ui/atoms/FileIconUri';

type ShowActivityDrawerProps = {
    drawerId: string;
};

type ApiResponse = {
    data?: any;
    error?: any;
};

type DrawerImagesIpfs = {
    id: string;
    imagesIpfs: { imageUrl: string; isImage: boolean }[];
    imageInfo: any;
};

const getImageInfo = async (drawerId: string, token: string) =>
    new Promise<string[]>((resolve, reject) => {
        axios
            .get(`${API_ADDRESS}/imageInfo/${drawerId}`, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                //console.log('GET ImageInfo RESPONSE: ', res);
                resolve(res.data.imageInfo);
            })

            .catch((err) => {
                reject(err);
            });
    });

const getSowingByIpfS = async (drawerId: string, token: string) =>
    new Promise<string[]>((resolve, reject) => {
        //setLoading(true);
        const apiResponse: ApiResponse = {};
        axios
            .get(`${API_ADDRESS}/images/${drawerId}`, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                //console.log('GET sowingIPFS RESPONSE: ', res);
                apiResponse.data = res.data.ipfsPaths;
                resolve(res.data.ipfsPaths);
            })

            .catch((err) => {
                reject(err);
            });
    });

const prepareFileImagesToRender = async (drawerId: string, token: string) => {
    //console.log('Files in confirmation', drawerId);
    let drawerImagesIpfs: DrawerImagesIpfs;
    try {
        const response = await getSowingByIpfS(drawerId, token);
        const imageInfo = await getImageInfo(drawerId, token);

        const transformedResponse = await Promise.all(
            response.map(async (item) => ({
                imageUrl: item,
                isImage: await isImgUrl(`https://bclabum.informatika.uni-mb.si/ipfs/ipfs/${item}`),
                imageInfo: imageInfo,
            })),
        );

        //console.log('Await response ', response);
        drawerImagesIpfs = {
            id: drawerId,
            imagesIpfs: transformedResponse,
            imageInfo: imageInfo,
        };
        return drawerImagesIpfs;
    } catch (err) {
        //console.log('Error has occured getting images from drawer id:', drawerId);
    }
};
const timestampToDate = (timestamp: string | null): any => {
    if (timestamp != null && timestamp.length != 0) {
        const date = new Date(parseInt(timestamp) * 1000);
        return formatDateToString(date, false);
    }
    return 'BrezDatuma';
};

const ShowActivityDrawer = ({ drawerId }: ShowActivityDrawerProps): ReactElement => {
    const { currentUser } = useAuth();
    const [drawerData, setDrawerData] = useState<DrawerImagesIpfs>();

    useEffect(() => {
        //console.log('OverviewFinishedSowings DRAWER_IDS', drawerId);
        if (drawerId !== undefined) {
            prepareFileImagesToRender(drawerId, currentUser.token)
                .then((item) => {
                    console.log('Returned DRAWER items: ', item);
                    setDrawerData(item);
                })
                .catch((err) => {
                    console.error('Error');
                });
        }
    }, []);

    return (
        <ShowActivityDrawerWrapper>
            {drawerData != undefined && (
                <>
                    <Test>
                        {timestampToDate(drawerData.imageInfo.inputDate)} {drawerData.imageInfo.description}
                    </Test>
                    <DrawerCard>
                        {drawerData.imagesIpfs.map((image, i) => (
                            <a key={i} href={`https://bclabum.informatika.uni-mb.si/ipfs/ipfs/${image.imageUrl}`}>
                                <Image>
                                    <img
                                        src={
                                            image.isImage
                                                ? `https://bclabum.informatika.uni-mb.si/ipfs/ipfs/${image.imageUrl}`
                                                : FileIconUri()
                                        }
                                        alt="Datoteka"
                                    />
                                </Image>
                            </a>
                        ))}
                    </DrawerCard>
                </>
            )}
        </ShowActivityDrawerWrapper>
    );
};

export default ShowActivityDrawer;

const ShowActivityDrawerWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const DrawerCard = styled.div`
    display: flex;
    flex-direction: row;
    margin: 1em;
`;

const Image = styled.div`
    display: flex;
    width: 200px;
    height: 100px;
    max-height: 100px;
    max-width: 120px;
    min-width: 120px;
`;

const Test = styled.div``;
